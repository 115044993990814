import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListCommercialProperties from "@components/commercial-properties/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "commercial-properties.getAll",
  query: 'department=commercial&deleteAttempt=0'
}

const CommercialListPage = (props) => (
  <>
    <Seo title="Commercial Properties List" />
    <div className="main-wrapper">
      <Sidebar menuActive="commercial-properties" />
      <ListCommercialProperties
        addBtnText="Commercial Property"
        menu="Commercial Properties" 
        parentPath="commercial-properties" 
        pageType="List"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default CommercialListPage;
